import React from "react"
import { connect } from "react-redux";
import { setContactFrame } from "../../redux/actions/actions";

import "./nav.scss";

const Nav = (props) => (
  <nav>
      <ul>
        <li>
          <button
            onClick={() => props.setContactFrame(props.frameState)}
          >
            Contact us
          </button>
        </li>
      </ul>
  </nav>
)

const mapStateToProps = state => ({
  frameState: state.app.frameState
})

export default connect(mapStateToProps, { setContactFrame })(Nav)