import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Nav from '../nav/nav'

import './header.scss'

const Header = ({headerClass}) => (
  <header className={headerClass}>
    <div className="container">
      <div className="logo">
        <h1>
          <AniLink 
            cover
            direction="left"
            bg="#767676"
            to="/"
          ><b>WRR</b> Enterprises<span>&reg;</span></AniLink></h1>
      </div>
      <Nav />
    </div>
  </header>
)

export default Header