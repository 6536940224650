import React from 'react'
import { connect } from "react-redux";

import './contact.scss'

const Contact = (props) => {
    return(
        <div className={`contact-frame ${props.frameState ? 'active-frame' : ''}`}>
            <h3>Contact info:</h3>
            <ul>
                <li>60 Rochester Hill Rd. Unit 4</li>
                <li>PH: <a href="tel:1-603-330-9600">+1 603 330 9600</a></li>
                <li>Email: <a href="mailto:wriendeau@wrrllc.com">wriendeau@wrrllc.com</a></li>
            </ul>
        </div>
    )
}

const mapStateToProps = state => ({
    frameState: state.app.frameState
})

export default connect(mapStateToProps, null)(Contact)