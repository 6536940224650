import PropTypes from "prop-types"
import React from "react"

import "./footer.scss"

const theDate = new Date()
const theYear = theDate.getFullYear();

const Footer = ({ siteTitle, footerClass }) => {

  return (
    <footer className={footerClass}>
      <div className="container">
        <div className="copyright">
          <p>&copy; {theYear} {siteTitle} | All rights reserved</p>
        </div>
      </div>
    </footer>
  )
}
  
Footer.propTypes = {
  footerClass: PropTypes.string,
  siteTitle: PropTypes.string
}

Footer.defaultProps = {
  footerClass: ``,
  siteTitle: ``
}

export default Footer;